import React, { useState, useEffect } from 'react';
import axios from 'axios/axios';
import { Table, Dropdown, Modal } from 'react-bootstrap';
import "../../css/Variants.css";
import toast from 'react-hot-toast';

const AddEditProduct = ({product , setReload}) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [nameAr, setNameAr] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [allCategorise, setAllCategorise] = useState([])
  const [allSubCategorise, setAllSubCategorise] = useState([])
  const [price, setPrice] = useState('');
  const [stock, setStock] = useState('');
  const [colorID, setColorID] = useState('');
  const [colorName, setColorName] = useState('');
  const [size, setSize] = useState('');
  const [colors, setColors] = useState([]);
  const [allVars, setAllVars] = useState([]);
  const [variantImages, setVariantImages] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [sizeImage, setSizeImage] = useState(null)
  const [categoryName, setCategoryName] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryName, setSubCategoryName] = useState(null)
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [newImages, setNewImages] = useState([]);
  


  useEffect(() => {
    if (product._id) {
      setCategoryId(product?.subCategory?.category?._id);
      setCategoryName(product?.subCategory?.category?.name);
      setSubCategoryName(product?.subCategory?.name);
      setSubCategoryId(product?.subCategory?._id);
      setName(product?.name);
      setNameAr(product?.nameAr);
      setDescription(product?.description);
      setDescriptionAr(product?.descriptionAr);
  
      // const variants = product.variants?.map((variant) => ({
      //   ...variant,
      //   images: variant.images || [],
      // })) || [];
  
      setAllVars(product.variants)
      setSizeImage(product?.sizeImage);
    } else {
      resetForm();
    }
  }, [product._id]);
  
  



  const getColors = () => {
    axios
    .get("/colors/all")
    .then((res) => setColors(res.data.colors))
    .catch((_) => {});
  };
  const getcategories = () => {
    axios
    .get("/products/categories")
    .then((res) => setAllCategorise(res.data.categories))
    .catch((_) => {});
  };
  const getSubCategories = () => {
    axios
    .get("/products/subCategories")
    .then((res) => setAllSubCategorise(res.data))
    .catch((_) => {});
  };

  useEffect(() => {
    getColors();
    getcategories();
    getSubCategories()
  }, []);






  const addVariant = () => {
    const filteredImages = variantImages.filter((img) => !removedImages.includes(img));
  
    if (!price || !stock || !size || !colorID) {
      return toast.error("please fill all Data")
    }
    else if (isEditing) {
      setAllVars((prevVars) => {
        const updatedVars = [...prevVars];
        return updatedVars;
      });
      setIsEditing(false);
      setEditIndex(null);
    } else {
      setAllVars((prevVars) => [
        ...prevVars,
        { price, stock, color: colorID, size, images: [...filteredImages] },
      ]);
      setVariantImages([])
    }
  
    setPrice('');
    setSizeImage(null)
    setStock('');
    setSize('');
    setColorID('');
    setColorName('');
    // setVariantImages([]);
    // setNewImages([]);
    // setRemovedImages([]);
  };
  

  console.log("isEditing" , isEditing);
  

  const handleEditClick = (variant , index) => {
    console.log("variant" , variant);
    setPrice(variant.price);
    setStock(variant.stock);
    setColorID(variant.color._id || variant?.color);
    setColorName(variant.color.name || colors.find(color => color._id === variant?.color).name);
    setSize(variant.size);
    setVariantImages(variant.images);
    setNewImages(variant.newImages || []);
    setIsEditing(true);
    setEditIndex(index)
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };


  

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (isEditing) {
      setAllVars((prevVars) => {
        const updatedVars = [...prevVars];
        const currentVariant = updatedVars[editIndex];
        currentVariant.images = [...(currentVariant.images || []), ...files];
        return updatedVars;
      })
    }else{
      setVariantImages(files)
    }
  };
  
  
  
  
  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);
    const files = Array.from(e.dataTransfer.files);
    setNewImages((prev) => [...prev, ...files]);
  };
  

  const getImages = (images) => {
    handleShow();
    const filteredImages = images.filter((img) => !removedImages.includes(img));
    setVariantImages(filteredImages)
  };

  const deleteVariant = (index) => {
    setAllVars((prevVars) => prevVars.filter((_, i) => i !== index));
  };

  


  const handleClose = () => {
    newImages.forEach((img) => {
      if (img instanceof File) {
        URL.revokeObjectURL(img);
      }
    });
    setShow(false);
  };  
  const handleShow = () => setShow(true);




  const [loading, setLoading] = useState(false)

  const addProduct = (e) => {
    e.preventDefault();
    const hasEmptyImages = allVars?.some((variant) => variant.images.length === 0);

   if(product._id && hasEmptyImages){
      toast.error("Each variant must have at least one image.");
      return;
   } 
    let ProductFormData = new FormData();
    ProductFormData.append("name", name);
    ProductFormData.append("nameAr", nameAr);
    ProductFormData.append("description", description);
    ProductFormData.append("descriptionAr", descriptionAr);
    ProductFormData.append("sizeImage", sizeImage);
    ProductFormData.append("category", categoryId);
    ProductFormData.append("subCategory", subCategoryId);
  
    const variantsFormData = new FormData();
  
    allVars?.forEach((variant, variantIndex) => {
      variantsFormData.append(`variants[${variantIndex}][size]`, variant.size);
      variantsFormData.append(`variants[${variantIndex}][price]`, variant.price);
      variantsFormData.append(`variants[${variantIndex}][stock]`, variant.stock);
      variantsFormData.append(
        `variants[${variantIndex}][color]`,
        product._id ? variant.color._id || variant.color : variant.color
      );
  
      const filteredImages = variant.images.filter((img) => !removedImages.includes(img));
      filteredImages.forEach((image, imageIndex) => {
        variantsFormData.append(`variants[${variantIndex}][images][${imageIndex}]`, image);
      });

  
      if (variant.newImages && variant.newImages.length > 0) {
        variant.newImages.forEach((image, imageIndex) => {
          variantsFormData.append(
            `variants[${variantIndex}][images][${filteredImages.length + imageIndex}]`,
            image
          );
        });
      }
    });
    

  
    for (let [key, value] of variantsFormData.entries()) {
      ProductFormData.append(key, value);
    }
  
    // console.log("Logging ProductFormData:");
    // for (let [key, value] of ProductFormData.entries()) {
    //   console.log(key, value);
    // }
  
    const requestConfig = {
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };
  
    const request = product._id
      ? axios.patch(`products/updateProduct/${product._id}`, ProductFormData, requestConfig)
      : axios.post(`products/addProduct`, ProductFormData, requestConfig);
  
    setLoading(true);
    request
      .then((response) => {
        toast.success(response?.data?.message);
        setReload(true);
        resetForm();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  
  
  
  const resetForm = () => {
    setCategoryId(null);
    setCategoryName(null);
    setSubCategoryName(null);
    setSubCategoryId(null);
    setName('');
    setNameAr('');
    setDescription('');
    setDescriptionAr('');
    setAllVars([]);
    setSizeImage(null);
    setVariantImages([]);
    setNewImages([]);
    setRemovedImages([]);
    setIsEditing(false);
    product._id = null
  };
  
  
  
  const [removedImages, setRemovedImages] = useState([]);
  
  const removeImage = (index, isOldImage) => {
    if (isOldImage) {
      const imageToRemove = variantImages[index];
      setRemovedImages((prev) => [...prev, imageToRemove]);
      setVariantImages((prev) => prev.filter((_, i) => i !== index));
    } else {
      setNewImages((prev) => prev.filter((_, i) => i !== index));
    }
  };


  console.log("removed" , removedImages);


  return (
    <>

      <form className='w-100'>
        <div className="row w-100 gy-3">
          <div className="col-md-6">
            <label htmlFor="name">Name</label>
            <input type="text" id='name' onChange={e => setName(e.target.value)} value={name} className='form-control' placeholder='Enter Product Name' />
          </div>
          <div className="col-md-6">
            <label htmlFor="NameAr">Name Ar</label>
            <input type="text" id='NameAr' className='form-control' onChange={e => setNameAr(e.target.value)} value={nameAr} placeholder='Enter Product Name Arabic' />
          </div>
          <div className="col-md-6">
            <label htmlFor="Description">Description</label>
            <textarea type="text" id='Description' className='form-control' onChange={e => setDescription(e.target.value)} value={description} placeholder='Enter Description' />
          </div>
          <div className="col-md-6">
            <label htmlFor="DescriptionAr">Description Ar</label>
            <textarea type="text" id='DescriptionAr' className='form-control' onChange={e => setDescriptionAr(e.target.value)} value={descriptionAr} placeholder='Enter Description Arabic' />
          </div>
          
          <div className="col-md-6">
            <label htmlFor="Categorise">All Categorise</label>
            <Dropdown>
                  <Dropdown.Toggle variant="white" className='w-100 d-flex justify-content-between border align-items-center' id="dropdown-basic">
                    {categoryName ? categoryName : "Select Category"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='w-100'>
                    {allCategorise?.map((category, index) =>
                      <Dropdown.Item key={index} onClick={() => {setCategoryName(category.name)
                        setCategoryId(category._id)
                      }}>{category.name}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
          </div>
          <div className="col-md-6">
            <label htmlFor="Categorise">All SubCategorise</label>
            <Dropdown>
                  <Dropdown.Toggle variant="white" className='w-100 d-flex justify-content-between border align-items-center' id="dropdown-basic">
                    {subCategoryName ? subCategoryName : "Select SubCategory"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='w-100'>
                    {allSubCategorise?.map((sub, index) =>
                      <Dropdown.Item key={index} onClick={() => {setSubCategoryName(sub.name)
                        setSubCategoryId(sub._id)
                      }}>{sub.name}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
          </div>
          <div className="col-md-12">
            <label htmlFor="sizeImages">Size Images</label>
            <input type="file" id='sizeImages' className='form-control w-50' onChange={e => setSizeImage(e.target.files[0])} accept='image/*' placeholder='Enter Size Image ' />
          </div>
          <div className="col-md-6">
            <div className="row gy-2">
              <h4>Add Variant</h4>
              <div className="col-md-12">
                <label htmlFor="price">Price</label>
                <input type="number" id='price' className='form-control' onChange={e => setPrice(e.target.value)} value={price} placeholder='Enter price' />
              </div>
              <div className="col-md-12">
                <label htmlFor="stock">Stock</label>
                <input type="number" id='Stock' className='form-control' onChange={e => setStock(e.target.value)} value={stock} placeholder='Enter Stock' />
              </div>
              <div className="col-md-12">
                <label htmlFor="sizes">Sizes</label>
                <Dropdown>
                  <Dropdown.Toggle variant="white" className='w-100 d-flex justify-content-between border align-items-center' id="dropdown-basic">
                    {size ? size : "Select Size"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='w-100'>
                    {["XS", "S", "M", "L", "XL", "2XL", "L-XL", "S-M", "One Size"].map((option, index) =>
                      <Dropdown.Item key={index} onClick={() => setSize(option)}>{option}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-12">
                <label htmlFor="sizes">Colors</label>
                <Dropdown>
                  <Dropdown.Toggle variant="white" className='w-100 d-flex justify-content-between border align-items-center' id="dropdown-basic">
                    {colorName ? colorName : "Select Color"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='w-100'>
                    {colors.map((color, index) =>
                      <Dropdown.Item key={index} onClick={() => {
                        setColorID(color._id);
                        setColorName(color.name);
                      }}>{color.name}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {(allVars.length > 0  && allVars.some(item => item.color === colorID)) || !colorID ? "" : <div className="col-md-12">
                <form
                  className={`file-upload-form ${dragActive ? 'drag-active' : ''}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <label htmlFor="file" className="file-upload-label">
                    {variantImages.length > 0 &&
                      variantImages.map((img, index) => (
                        <span className="pe-2" key={index}>
                          {img.name}
                        </span>
                      ))}
                    <div className="file-upload-design">
                      <svg viewBox="0 0 640 512" height="1em">
                        <path
                          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                        ></path>
                      </svg>
                      <p>Drag and Drop</p>
                      <p>or</p>
                      <span className="browse-button">Browse file</span>
                    </div>
                    <input
                      id="file"
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </label>
                </form>
              </div> }

              {isEditing && <div className="col-md-12">
                <form
                  className={`file-upload-form ${dragActive ? 'drag-active' : ''}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <label htmlFor="file" className="file-upload-label">
                    {variantImages.length > 0 &&
                      variantImages.map((img, index) => (
                        <span className="pe-2" key={index}>
                          {img.name}
                        </span>
                      ))}
                    <div className="file-upload-design">
                      <svg viewBox="0 0 640 512" height="1em">
                        <path
                          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                        ></path>
                      </svg>
                      <p>Drag and Drop</p>
                      <p>or</p>
                      <span className="browse-button">Browse file</span>
                    </div>
                    <input
                      id="file"
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </label>
                </form>
              </div>}
              
              <div className='text-end'>
                <button className='btn btn-danger' type='button' onClick={addVariant}>
                  <i className={`fa-solid fa-${isEditing ? "edit" : "plus"}`}></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Table hover className='text-center'>
              <thead>
                <tr>
                  <th>Price</th>
                  <th>Stock</th>
                  <th>Color</th>
                  <th>Size</th>
                  <th>Images</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allVars.map((variant, index) =>
                  <tr key={index}>
                    <td>{variant.price}</td>
                    <td>{variant.stock}</td>
                    <td>{colors.find(col => col._id === variant.color)?.name || variant.color.name}</td>
                    <td>{variant.size}</td>
                    <td>
                      <i className="fa-solid fa-image" style={{ cursor: "pointer" }} onClick={() => { getImages(variant.images) }}></i>
                    </td>
                    <td>
                      <i className="fa-regular fa-pen-to-square" style={{ cursor: "pointer" }} onClick={() => handleEditClick(variant , index)}></i>
                      <i className="fa-solid fa-trash" style={{ cursor: "pointer", marginLeft: '10px' }} onClick={() => deleteVariant(index)}></i>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="text-end mt-2">
        <button type='submit' disabled={loading} className='btn btn-black btn-dark px-5 rounded-4' onClick={(e)=>{addProduct(e)}}>{loading ? <i className="fa-solid fa-spinner fa-spin"></i> : product._id ? "Edit" :  "Submit"}</button>
        </div>
      </form>

      <Modal show={show} onHide={handleClose}>
  <Modal.Body>
    {[
      ...variantImages.map((img, index) => ({
        src: img?.name ? URL.createObjectURL(img) : img,
        isOld: true,
        index,
      })),
      ...newImages.map((img, index) => ({
        src: URL.createObjectURL(img),
        isOld: false,
        index,
      })),
    ].map((image, unifiedIndex) => (
      <div key={`image-${unifiedIndex}`} className="image-preview">
        <img src={image.src} className="w-100" alt="product" />
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => removeImage(image.index, image.isOld)}
        >
          Remove
        </button>
      </div>
    ))}
  </Modal.Body>
      </Modal>

    </>
  );
};

export default AddEditProduct;